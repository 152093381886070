

:root {
    --primary: rgb(0, 2, 96);
    --overlay: rgba(0, 0, 111, 0.6);
  }
  
  
 main {
    background-color: rgb(255, 255, 255);
    width: 700px;
    height: 400px;
    margin: 0 auto; /* Centers the main element horizontally */
    position: relative; /* Contains the absolutely positioned children */
    font: 0.7rem 'Impact', sans-serif;
    border-radius: 20px;
    padding: 30px;
    
  }
  
  main header {
    font-size: 1.2rem;
    text-transform: uppercase;
    color: rgba(20, 0, 107, 0.992);
    width: 100%; /* Full width of main */
    text-align: start; /* Center the text in the header */
    margin-left: 30px;
  }
  
  main header span {
    color: var(--primary); /* Color from CSS variable */
  }
  
  main section {
    display: flex;
    gap: 2rem;
    width: 100%; /* Ensure section spans full width of main */
  }
  
  .indexes, .tabs { list-style-type: none; }
  
  .indexes {
    font-size: 1rem;
  
    & li {
      padding: 1rem;
      border: 1px solid transparent;
      cursor: pointer;
    }
  }
  
  .tabs { position: relative; }
  
  .tab {
    position: absolute;
    display: flex;
    width: 550px;
    height: 305px;
    opacity: 0; 
    /* background-color: rgba(0, 0, 0, 0.26); */
    overflow: hidden;
  }
  
  .tab-content {
    position: relative;
    z-index: 5;
    width: 300px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform: translateY(-5rem);
  
    & h3 {
      font-family: helvetica;
      font-weight: 900;
      font-size: 1.5rem;
      border-bottom: 1.5px solid rgb(0, 9, 86);
      padding-bottom: 1rem;
      color: #001258;
    }
  
    & p {
      font-family: helvetica;
      font-weight: 400;
      line-height: 2;
      color: rgb(7, 0, 101);
      font-size: 12px;

    }
  
    & button {
      width: fit-content;
      background-color: transparent;
      color: rgb(0, 3, 97);
      /* border: 1px solid rgb(255, 255, 255); */
      font-size: 0.8rem;
      padding: 0.75rem 1rem;
      cursor: pointer;
    }
  }
  
  @keyframes content {
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .tab-image {
    position: absolute;
    right: 1rem;
    width: 300px;
    height: 300px;
    opacity: 0;
    transform: translateX(2rem);
  
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-color: var(--overlay);
      mix-blend-mode: multiply;
    }
  
    & img {
      width: inherit;
      height: inherit;
      object-fit: cover;
      filter: grayscale(100%);
    }
  }
  
  @keyframes image {
    100% {
      opacity: 1;
      width: 300px;
      transform: translateX(0);
    }
  }
  
  .active .tab          { opacity: 1; z-index: 5; }
  .active .tab-content  { animation: content 0.9s ease-out 0.4s forwards; }
  .active .tab-image    { animation: image 1s ease-out forwards; }


  @media (max-width: 900px) {


      
  
 main {
    background-color: rgb(0, 0, 71);
    width: 400px;
    height: 250px;
    margin: 0 auto; /* Centers the main element horizontally */
    position: relative; /* Contains the absolutely positioned children */
    font: 0.5rem 'Impact', sans-serif;
    border-radius: 12px;
    padding: 15px;
  }
  
  main header {
    font-size: 0.8rem;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.992);
    width: 70%; /* Full width of main */
    text-align: start; /* Center the text in the header */
    margin-left: 18px;
  }
  
  main header span {
    color: var(--primary); /* Color from CSS variable */
  }
  
  main section {
    display: flex;
    gap: 0.7rem;
    width: 70%; /* Ensure section spans full width of main */
  }
  
  .indexes, .tabs { list-style-type: none; }
  
  .indexes {
    font-size: 0.7rem;
  
    & li {
      padding: 1rem;
      border: 1px solid transparent;
      cursor: pointer;
    }
  }
  
  .tabs { position: relative; }
  
  .tab {
    position: absolute;
    width: 380px;
    height: 210px;
    opacity: 0; 
    /* background-color: rgba(0, 0, 0, 0.26); */
    overflow: hidden;
  }
  
  .tab-content {
    position: relative;
    z-index: 5;
    width: 180px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform: translateY(-5rem);
  
    & h3 {
      font-family: helvetica;
      font-weight: 600;
      font-size: 0.8rem;
      border-bottom: 1.5px solid rgb(255, 255, 255);
      padding-bottom: 1rem;
      color: #ffffff;
    }
  
    & p {
      font-family: helvetica;
      font-weight: 400;
      line-height: 1.2;
      color: rgb(255, 255, 255);
      font-size: 11px;

    }
  
    & button {
      width: fit-content;
      background-color: transparent;
      color: rgb(255, 255, 255);
      /* border: 1px solid rgb(255, 255, 255); */
      font-size: 0.5rem;
      padding: 0.5rem 0.5rem;
      cursor: pointer;
    }
  }
  
  @keyframes content {
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .tab-image {
    position: absolute;
    right: 1rem;
    width: 140px;
    height: 140px;
    opacity: 0;
    transform: translateX(1rem);
  
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-color: var(--overlay);
      mix-blend-mode: multiply;
    }
  
    & img {
      width: inherit;
      height: inherit;
      object-fit: cover;
      filter: grayscale(100%);
    }
  }
  
  @keyframes image {
    100% {
      opacity: 1;
      width: 280px;
      margin-right: 50px;
      transform: translateX(0);
    }
  }
  
  .active .tab          { opacity: 1; z-index: 5; }
  .active .tab-content  { animation: content 0.9s ease-out 0.4s forwards; }
  .active .tab-image    { animation: image 1s ease-out forwards; }



  }

  