.service-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    text-align: center;
}

.service-container h1 {
    font-size: 36px;
    color: #0047ab;
    margin-bottom: 20px;
}

.service-image {
    width: 250px;
    height: 250px; /* Set height equal to width for a perfect circle */
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 50%; /* Make the image circular */
}

.service-content {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
    text-align: left;
    padding: 0 20px;
}
