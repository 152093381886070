#slider {
    display: flex;
    gap: 15px;
    font-size: 30px;
    color: #0077ee;
    font-weight: 700;
  }
  
  .letter {
    opacity: 0;
    animation: fade 0.3s forwards;
  }
  
  @keyframes fade {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animation.start {
    animation-name: fade, holder;
    animation-duration: 0.3s, 4s;
    animation-timing-function: forwards, ease;
    animation-fill-mode: forwards;
    animation-iteration-count: 1, infinite;
  }
  
  @keyframes holder {
    0%, 95% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  