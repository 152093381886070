.about-container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.about-header {
  text-align: center;
  margin-bottom: 40px;
}

.about-header h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #0047ab;
}

.about-header-image {
  width: 100%;
  height: auto;
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 40px;
}

.about-text {
  flex: 1;
  padding: 20px;
}

.about-text h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #0047ab;
}

.about-text p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.about-image {
  flex: 1;
  text-align: center;
  padding: 20px;
}

.about-image img {
  width: 80%;
  height: auto;
  max-width: 300px;
}

.business-hours {
  margin-bottom: 40px;
}

.business-hours h2 {
  font-size: 24px;
  color: #0047ab;
  margin-bottom: 20px;
}

.hours-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.hours-column {
  flex: 1;
  padding: 20px;
}

.hours-column h3 {
  font-size: 18px;
  color: #0047ab;
  margin-bottom: 10px;
}

.hours-column p {
  font-size: 16px;
  margin-bottom: 10px;
}

.hours-column ul {
  list-style-type: none;
  padding: 0;
}

.hours-column li {
  font-size: 16px;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
  }

  .about-text, .about-image {
    padding: 10px;
  }

  .about-image img {
    width: 100%;
    max-width: 200px;
  }

  .hours-table {
    flex-direction: column;
  }

  .hours-column {
    padding: 10px;
  }
}
