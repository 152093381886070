.hexagon-team-container {
    text-align: center;
    padding: 50px 20px;
    background-color: #f0f4f8;
    border-radius: 10px;
    max-width: 1000px;
    margin: 0 auto;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.team-heading {
    font-size: 40px;
    color: #00254D;
    margin-bottom: 20px;
    font-family: 'Nunito', sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.team-intro {
    font-size: 18px;
    color: #555555;
    font-family: 'Nunito', sans-serif;
    margin-bottom: 40px;
    line-height: 1.6;
}

.team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 30px;
}

.team-member {
    flex: 1 1 30%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-member:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.member-name {
    font-size: 26px;
    color: #00254D;
    margin-bottom: 8px;
    font-family: 'Nunito', sans-serif;
}

.member-position {
    font-size: 18px;
    color: #777777;
    font-family: 'Nunito', sans-serif;
}
