.products-page {
    padding: 20px;
  }
  
  .category {
    margin-bottom: 40px;
  }
  
  .category-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .category-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .category-list {
    list-style: none;
    padding: 0;
  }
  
  .item {
    margin-bottom: 5px;
  }
  
  .item-link {
    text-decoration: none;
    color: #007bff;
  }
  
  .item-link:hover {
    text-decoration: underline;
  }
  
  .category-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 50%;
    margin-top: 20px;
  }
  
  @media (min-width: 768px) {
    .category-content {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  
    .category-list {
      flex: 1;
    }
  
    .category-image {
      flex: 1;
      margin-left: 20px;
      max-width: 200px;
    }
  }
  