.slideshow-landing-page {
    position: absolute;
    left: 0;
    width: 100%;
    height: 95%;
    opacity: 5;

  }
  
  .slide-landing-page {
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.547);


    background: no-repeat center center/cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: slideShowLandingPage 8s infinite;
    opacity: 0;
    
  }
  
  @keyframes slideShowLandingPage {
    0%, 100% { opacity: 0; }
    16.6%, 50% { opacity: 1; } /* Maintain full opacity from 16.6% to 50% */
  }
  
  .slide-landing-page:nth-child(1) {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/static%2FlDsOMwB%20-%20Imgur.jpg?alt=media&token=72a9ea16-d7f7-44bd-8443-aa2dfefcdefc');
    animation-delay: 0s; /* No delay for first slide */
  }
  
  .slide-landing-page:nth-child(2) {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/static%2FhXhZRyq%20-%20Imgur.jpg?alt=media&token=38d720ed-0cdb-4654-a89e-507ee549729d');
    animation-delay: -20s; /* Delay for second slide */
  }
  
  .slide-landing-page:nth-child(3) {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/static%2FHyJZj5q%20-%20Imgur.jpg?alt=media&token=854e614b-eec6-4332-89c3-8a2503a25290');
    animation-delay: -10s; /* Delay for third slide */
  }

  .slide-landing-page:nth-child(4) {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/static%2FHNkNRXs%20-%20Imgur.jpg?alt=media&token=833c553c-be29-4be4-8299-65270ace10a1');
    animation-delay: -10s; /* Delay for third slide */
  }
  
  .slide-landing-page:nth-child(5) {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/static%2FcJlFjyt%20-%20Imgur.png?alt=media&token=e84c53f1-5e1a-4952-8b61-bd5fd6830588');
    animation-delay: -10s; /* Delay for third slide */
  }
  

  @media (max-width: 700px) {

    .slideshow-landing-page {
      position: absolute;
      left: 0;
      width: 100%;
      height: 60%;
      opacity: 5;
  
    }

  }


  @media (max-width: 1000px) {
  
    .slideshow-landing-page {
      position: absolute;
      left: 0;
      width: 100%;
      height: 80%;
      opacity: 5;
  
    }

  }

  
  @media (max-width: 900px) {
  
    .slideshow-landing-page {
      position: absolute;
      left: 0;
      width: 100%;
      height: 70%;
      opacity: 5;
  
    }}