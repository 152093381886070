*{box-sizing: border-box; -webkit-box-sizing: border-box; }


/*
PURE RESPONSIVE CSS3 SLIDESHOW GALLERY by Roko C. buljan
http://stackoverflow.com/a/34696029/383904
*/

.CSSgal {
	position: relative;
	overflow: hidden;
	height: 100%; /* Or set a fixed height */
}

/* SLIDER */

.CSSgal .slider {
	height: 100%;
	white-space: nowrap;
	font-size: 0;
	transition: 0.8s;
}

/* SLIDES */

.CSSgal .slider > * {
	font-size: 1rem;
	display: inline-block;
	white-space: normal;
	vertical-align: top;
	height: 100%;
	width: 100%;
	background: none 50% no-repeat;
	background-size: cover;
}

/* PREV/NEXT, CONTAINERS & ANCHORS */

.CSSgal .prevNext {
	position: absolute;
	z-index: 1;
	top: 50%;
	width: 100%;
	height: 0;
}

.CSSgal .prevNext > div+div {
	visibility: hidden; /* Hide all but first P/N container */
}

.CSSgal .prevNext a {
	background: #fff;
	position: absolute;
	width:       60px;
	height:      60px;
	line-height: 60px; /* If you want to place numbers */
	text-align: center;
	opacity: 0.7;
	-webkit-transition: 0.3s;
					transition: 0.3s;
	-webkit-transform: translateY(-50%);
					transform: translateY(-50%);
	left: 0;
}
.CSSgal .prevNext a:hover {
	opacity: 1;
}
.CSSgal .prevNext a+a {
	left: auto;
	right: 0;
}

/* NAVIGATION */

.CSSgal .bullets {
	position: absolute;
	z-index: 2;
	bottom: 0;
	padding: 10px 0;
	width: 100%;
	text-align: center;
}
.CSSgal .bullets > a {
	display: inline-block;
	width:       30px;
	height:      30px;
	line-height: 30px;
	text-decoration: none;
	text-align: center;
	background: rgba(255, 255, 255, 1);
	-webkit-transition: 0.3s;
					transition: 0.3s;
}
.CSSgal .bullets > a+a {
	background: rgba(255, 255, 255, 0.5); /* Dim all but first */
}
.CSSgal .bullets > a:hover {
	background: rgba(255, 255, 255, 0.7) !important;
}

/* NAVIGATION BUTTONS */
/* ALL: */
.CSSgal >s:target ~ .bullets >* {      background: rgba(255, 255, 255, 0.5);}
/* ACTIVE */
#s1:target ~ .bullets >*:nth-child(1) {background: rgba(255, 255, 255,   1);}
#s2:target ~ .bullets >*:nth-child(2) {background: rgba(255, 255, 255,   1);}
#s3:target ~ .bullets >*:nth-child(3) {background: rgba(255, 255, 255,   1);}
#s4:target ~ .bullets >*:nth-child(4) {background: rgba(255, 255, 255,   1);}
/* More slides? Add here more rules */

/* PREV/NEXT CONTAINERS VISIBILITY */
/* ALL: */
.CSSgal >s:target ~ .prevNext >* {      visibility: hidden;}
/* ACTIVE: */
#s1:target ~ .prevNext >*:nth-child(1) {visibility: visible;}
#s2:target ~ .prevNext >*:nth-child(2) {visibility: visible;}
#s3:target ~ .prevNext >*:nth-child(3) {visibility: visible;}
#s4:target ~ .prevNext >*:nth-child(4) {visibility: visible;}
/* More slides? Add here more rules */

/* SLIDER ANIMATION POSITIONS */

#s1:target ~ .slider {transform: translateX(   0%); -webkit-transform: translateX(   0%);}
#s2:target ~ .slider {transform: translateX(-100%); -webkit-transform: translateX(-100%);}
#s3:target ~ .slider {transform: translateX(-200%); -webkit-transform: translateX(-200%);}
#s4:target ~ .slider {transform: translateX(-300%); -webkit-transform: translateX(-300%);}
/* More slides? Add here more rules */


/* YOU'RE THE DESIGNER! 
   ____________________
   All above was mainly to get it working :)
   CSSgal CUSTOM STYLES / OVERRIDES HERE: */

.CSSgal{
	color: #fff;	
	text-align: center;
}
.CSSgal .slider h2 {
	/* margin-top: 30vh; */
	font-weight: 200;
	letter-spacing: -0.06em;
	word-spacing: 0.2em;
	font-size: 3em;

}
.CSSgal a {
	border-radius: 50%;
	margin: 0 3px;
	color: rgba(0,0,0,0.8);
	text-decoration: none;
}

.slide {
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    padding: 20px;
}

.fifth-comp-button {
    padding: 12px 24px; /* Slightly larger padding for a better touch target */
    background-color: #004085; /* A darker blue shade */
    color: white;
    border: none;
    border-radius: 6px; /* Slightly more rounded corners */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    font-size: 18px; /* Larger font size for readability */
    margin-top: 20px; /* Increased spacing from the text above */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for 3D effect */
    margin: 15px;
}

.fifth-comp-button:hover, .fifth-comp-button:focus {
    background-color: #002752; /* Even darker shade on hover/focus for better accessibility */
    color: #e0e0e0; /* Light grey text on hover for contrast */
    transform: translateY(-2px); /* Subtle lift effect on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increased shadow on hover for more depth */
}

.slider p {
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    font-weight:700;
    margin: 15px;
    margin-left: 100px;
    margin-right: 100px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .fifth-comp-button {
        padding: 10px 20px; /* Smaller padding on smaller screens */
        font-size: 16px; /* Smaller font size for smaller screens */
        margin-top: 15px;
    }
    .slider p {
        text-align: center;
        color: #ffffff;
        font-size: 12px;
        font-weight:500;
        margin: 30px;
        margin-left: 50px;
        margin-right: 50px;    }

        
    
    .CSSgal .prevNext a {
        background: #fff;
        position: absolute;
        width:       30px;
        height:      30px;
        line-height: 30px; /* If you want to place numbers */
        text-align: center;
        opacity: 0.7;
        -webkit-transition: 0.3s;
                        transition: 0.3s;
        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
        left: 0;
        font-size: 8px;
    }
}
