.hexagon-services-layout {
  padding: 50px;
}

.services-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
}

.service {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-left: 5px solid #0047ab;
  padding: 10px;
}

.service-text {
  flex: 1;
}

.service-title {
  font-size: 18px;
  font-weight: bold;
  color: #0047ab;
}

p {
  font-size: 16px;
  line-height: 1.6;
}

.service-image {
  width: 200px;
  height: auto;
  margin-left: 20px;
}
