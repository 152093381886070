@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Outfit:wght@100..900&family=Sora:wght@100..800&display=swap");


.landing-page {
  font-family: "Sora", sans-serif;
  color: #333;
  line-height: 1.6;
  max-width: 1200px;
  margin: 0 auto;

}

.hero {
  background: url('https://firebasestorage.googleapis.com/v0/b/hexagon786.appspot.com/o/static%2FKWrxMRD%20-%20Imgur.png?alt=media&token=df5677c1-c2b4-4abd-b75e-ae2b03f6228b') no-repeat center center/cover;
  /* Add your path */
  color: #fff;
  text-align: center;
  padding: 100px 20px;
}

.hero h1 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

.hero p {
  font-size: 1.5em;
  margin-bottom: 1em;
}

.hero button {
  background-color: #0067ab;
  /* Adjust color to match your branding */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.hero button:hover {
  background-color: #00457c;
}

.about {
  background-color: #f4f4f4;
  padding: 50px 20px;
  text-align: center;
}

.about h2 {
  font-size: 2em;
  color: #333;
  margin-bottom: 0.5em;
}

.about p {
  font-size: 1.2em;
  max-width: 800px;
  margin: 0 auto;
}



.first-comp {
  display: flex;
  gap: 50px;

}

.first-comp .left-text {
  flex-basis: 50%;
  background-color: rgb(239, 239, 239);
  color: rgb(0, 31, 116);
  padding: 20px;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: flex-start; /* Aligns children to the start of the flex container */
  text-align: center; /* Centers text lines */
  border-radius: 25px;
  margin: 100px 30px; /* Combine top margin and side margins */
  font-size: 1.1em;
  position: relative;
  flex: 1;
  max-height: 400px; /* Adjust to your preference */
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */

}

.first-comp .left-text::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}




.first-comp .right-text {

  flex-basis: 50%;
  color: black;
  padding: 20px;
  position: relative;
  margin-top: 160px;

}



.left-text p {
  margin: 0;
  /* Removes default paragraph margin */

}

.left-text strong {
  font-weight: bold;
  /* Makes keywords bold */
}


.company-name-text {
  font-family: "Sora", sans-serif;
  position: absolute;
  top: 0;
  /* Adjust as needed to place it above the hexagon circle */
  left: 50%;
  transform: translate(-50%, -100%);
  /* Center and move it above the hexagon circle */
  font-size: 2em;
  /* Adjust size as needed */
  color: #001957;
  /* Adjust color as needed */
  font-weight: bold;
  white-space: nowrap;
  /* Prevent the text from wrapping */
  padding: 10px;
  background: rgba(255, 255, 255, 0);
  /* Optional: a subtle background to enhance readability */
  border-radius: 10px;
  /* Optional: rounded corners for the background */
  z-index: 10;
  /* Ensure it's above the hexagons */
  animation: fadeIn 1.5s ease-in-out forwards;

}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Instead of using <center>, use this CSS for centering */
.slogan-center {
  text-align: center;
  display: block;
}



.slogan-animation {
  display: inline-block;
  /* Allows block-level styling for inline elements */
  font-size: 1em;
  /* Start with a smaller font size */
  opacity: 0;
  /* Start fully transparent */
  animation: growAndFadeIn 3s ease forwards;
  /* Complete the animation in 2 seconds */
}



@media (max-width: 1000px) {

  .first-comp .left-text {
    flex-basis: 50%;
    background-color: rgb(239, 239, 239);
    color: rgb(0, 31, 116);
    padding: 20px;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: flex-start; /* Aligns children to the start of the flex container */
    text-align: center; /* Centers text lines */
    border-radius: 25px;
    margin: 100px 30px; /* Combine top margin and side margins */
    font-size: 0.8em;
    position: relative;
    flex: 1;
    max-height: 400px; /* Adjust to your preference */
    overflow-y: auto;
    scrollbar-width: none; /* For Firefox */
  
  }
}

@media (max-width: 900px) {

  .first-comp {
    display: flex;
    gap: 0px;
  
  }
.first-comp .left-text {

  flex-basis: 55%;
  /* Takes up half of the parent container's width */
  background-color: rgb(239, 239, 239);
  /* Background color of the div */
  color: rgb(0, 31, 116);
  /* Text color */
  padding: 20px;
  display: flex;
  /* Enables flexbox layout */
  justify-content: center;
  /* Centers content horizontally */
  align-items: center;
  /* Centers content vertically */
  text-align: center;
  /* Centers text lines */
  border-radius: 25px;
  margin-top: 100px;
  position: relative;
  margin: 15px;
  height: 230px;
  margin-top: 100px;


}
.left-text p {
  font-size: 13px;
  padding: 2px;
}

.first-comp .right-text {

  flex-basis: 45%;
  color: black;
  padding: 20px;
  position: relative;
  margin-top: 80px;

}

.company-name-text {
  font-family: "Sora", sans-serif;
  position: absolute;
  top: 0;
  /* Adjust as needed to place it above the hexagon circle */
  left: 50%;
  transform: translate(-50%, -100%);
  /* Center and move it above the hexagon circle */
  font-size: 1.2em;
  /* Adjust size as needed */
  color: #001957;
  /* Adjust color as needed */
  font-weight: bold;
  white-space: nowrap;
  /* Prevent the text from wrapping */
  padding: 10px;
  background: rgba(255, 255, 255, 0);
  /* Optional: a subtle background to enhance readability */
  border-radius: 10px;
  /* Optional: rounded corners for the background */
  z-index: 10;
  /* Ensure it's above the hexagons */
  animation: fadeIn 1.5s ease-in-out forwards;
  font-size: 20px;

}

}

@media (max-width: 700px) {

  .first-comp {
    flex-direction: column-reverse; /* This will place the right-text above the left-text */
  }



  .first-comp .right-text {
    flex-basis: 100%;
    margin-top: 50px;
  }


  .company-name-text {
    font-size: 1.2em;
    padding: 0.25em 0;
  }

  .hero h1 {
    font-size: 1em;
  }

  .hero p {
    font-size: 1.2em;
  }

  .left-text {
    margin-left: 30px;
    margin-right: 30px;
  }

  .left-text p {
    font: 0.8em sans-serif;
  }

  .first-comp .left-text {
    margin-top: 25px;
  }

  .first-comp .right-text {

    margin-top: 80px;

  }

}