body {
    font-family: 'Helvetica', Arial, sans-serif;
    margin: 0;
    color: #555;
}

.phone-icon {
    font-size: 20px;
}

#wrap {
    position: relative;
}

#header {
    background-color: #ffffff;
    height: 100px;
    width: 100%;
    color: #0a0059;
    font-size: 40px;
    line-height: 40px;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    z-index: 0;
    position: relative;
}

.logo-container .header-logo {
    height: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px;
}

.header-content {
    display: flex;
    align-items: center;
}

.phone-box {
    margin-top: 10px;
    width: 200px;
    overflow: hidden;
    position: relative;
    background-color: #ffffff45;
    border-radius: 15px;
    color: #000649;
    border: 2px solid #000649;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

@keyframes slow-blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.1;
    }
}

.contact-info {
    font-size: 16px;
}

#nav {
    height: 80px;
    line-height: 80px;
    background-color: #000947;
    text-align: center;
    z-index: 100;
    position: relative;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
}

#nav.fixed {
    position: fixed;
    top: 0;
    width: 100%;
}

#top {
    display: none;
    background: #f4726d;
    color: #fff;
    padding: 5px 10px;
    position: fixed;
    right: 10px;
    bottom: 20px;
    z-index: 9999;
}

#top[style="display: block;"] {
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.nav-item {
    position: relative;
    display: inline-block;
}

.nav-button {
    height: 50px;
    color: #ffffff;
    padding: 10px 30px;
    background-color: #000947;
    margin: 0 10px;
    cursor: pointer;
    font-weight: bold;
    border: none;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 15px;
    font-weight: 700;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-button:hover {
    background-color: #ffffff;
    color: rgb(0, 22, 93);
}

.dropdown-icon {
    margin-left: 5px;
    font-size: 12px;
}

.nav-item:hover .dropdown-menu {
    display: block;
}

.dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #000947;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 1;
    min-width: 200px;
    text-align: left;
}

.dropdown-menu button {
    background-color: #000947;
    color: #ffffff;
    padding: 10px 20px;
    width: 100%;
    border: none;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    display: block;
}

.dropdown-menu button:hover {
    background-color: #ffffff;
    color: rgb(0, 22, 93);
}

@media (max-width: 992px) {


    .logo-container .header-logo {
        height: 90px;
        margin-left: 10px;
    }

    .nav-button {
        font-size: 15px;
        padding: 5px 10px;

    }
    .location-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 10px 15px 0 10px;
        padding: 8px 12px;
        background-color: #dbdbdb;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        border-radius: 20px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        transition: background-color 0.3s, transform 0.3s;
        font-weight: 700;
    }
    
    .location-container:hover {
        background-color: #2200b9;
        transform: translateY(-2px);
    }
    
    .location-icon {
        margin-left: 8px;
        font-size: 16px;
    }
    
    
}

@media (max-width: 576px) {


    .logo-container .header-logo {
        height: 80px;
        margin-left: 10px;
    }

    .nav-button {
        font-size: 10px;
        padding: 5px 8px;

    }
    .location-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 10px 15px 0 10px;
        padding: 8px 12px;
        background-color: #dbdbdb;
        color: #070063;
        font-size: 14px;
        font-weight: 600;
        border-radius: 20px;
        box-shadow: 0px 4px 6px rgba(139, 136, 136, 0.1);
        transition: background-color 0.3s, transform 0.3s;
        font-weight: 700;
    }
    
    .location-container:hover {
        background-color: #100058;
        transform: translateY(-2px);
        color: #fdfdfd;
    
    }
    
    .location-icon {
        margin-left: 8px;
        font-size: 16px;
    }
    
}

@media (max-width: 450px) {
  
    .nav-button {
        font-size: 10px;
        padding: 5px 5px;

    }
    .location-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 10px 15px 0 10px;
        padding: 8px 12px;
        background-color: #dbdbdb;
        color: #070063;
        font-size: 14px;
        font-weight: 600;
        border-radius: 20px;
        box-shadow: 0px 4px 6px rgba(139, 136, 136, 0.1);
        transition: background-color 0.3s, transform 0.3s;
        font-weight: 700;
    }
    
    .location-container:hover {
        background-color: #100058;
        transform: translateY(-2px);
        color: #fdfdfd;
    
    }
    
    .location-icon {
        margin-left: 8px;
        font-size: 16px;
    }
    
}


.location-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 10px 15px 0 10px;
    padding: 8px 12px;
    background-color: #dbdbdb;
    color: #070063;
    font-size: 14px;
    font-weight: 600;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(139, 136, 136, 0.1);
    transition: background-color 0.3s, transform 0.3s;
    font-weight: 700;
}

.location-container:hover {
    background-color: #100058;
    transform: translateY(-2px);
    color: #fdfdfd;

}

.location-icon {
    margin-left: 8px;
    font-size: 16px;
}